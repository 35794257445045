table {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-bottom: 20px;
}
table td {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 5px;
}
table td input.small,
table td select.small {
    width: 100px;
}
table td input.large,
table td select.large,
table td textarea.large {
    width: 300px;
}
table.even-odd tr:nth-child(2n+1) {
    background: #999;
    color: #000;
}