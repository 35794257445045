select, input{
    width: 100%;
    height: 25px;
}
table{
    width: 100%;
}
.background{
    border: 2px solid #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
    background-color: rgb(20, 19, 19);
}