body.hb-open {
    overflow: hidden;
}
.header {
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
    left: 0;
    position: fixed;
    top: -50px;
    transition: top 0.3s ease-in-out;
    width: 100%;
    z-index: 10;
}
.header.hb-open,
.header.sticky {
    top: 0;
}
.header .banner1 {
    display: none;
}
.header .banner2 {
    background-color: #100e17;
    font-size: 0;
    height: 50px;
    width: 100%;
}
.header .banner2 .menu {
    display: inline-block;
    height: 50px;
    width: 25px;
}
.header .banner2 .menu .hb-menu {
    height: 50px;
    padding: 14.5px 0;
    position: absolute;
    left: 20px;
    top: 0;
    width: 25px;
}
.header .banner2 .menu .hb-menu span,
.header .banner2 .menu .hb-menu span:before,
.header .banner2 .menu .hb-menu span:after {
    background: #0175a2;
    border-radius: 10px;
    display: block;
    height: 3px;
    width: 25px;
}
.header .banner2 .menu .hb-menu span {
    position: relative;
    top: 8px;
    transition: transform 0.25s ease-in-out 0.15s;
}
.header.hb-open .banner2 .menu .hb-menu span {
    transform: rotate(-45deg);
}
.header .banner2 .menu .hb-menu span:before {
    content: '';
    position: absolute;
    top: -8px;
    transition: top 0.15s ease-in-out, transform 0.25s ease-in-out 0.15s;
}
.header .banner2 .menu .hb-menu span:after {
    content: '';
    position: absolute;
    top: 8px;
    transition: top 0.15s ease-in-out, transform 0.25s ease-in-out 0.15s;
}
.header.hb-open .banner2 .menu .hb-menu span:before,
.header.hb-open .banner2 .menu .hb-menu span:after {
    top: 0;
    transform: rotate(90deg);
}
.header.hb-open .banner2 .menu-mask {
    background: rgba(0,0,0,0.3);
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: auto;
    position: fixed;
    right: 0;
    top: 40px;
}
.header .banner2 .menu .items {
    background: #1d1e23;
    border-top: 3px solid #0175a2;
    bottom: 40px;
    left: 0;
    max-width: 300px;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 50px;
    transform: translateX(-100%);
    transition: all 0.3s ease-in;
    width: 70%;
}
.header.hb-open .banner2 .menu .items {
    transform: translateX(0);
}
.header .banner2 .menu .item {
    border-bottom: 1px solid #666;
    position: relative;
    width: 100%;
}
.header .banner2 .menu .item.drop h3,
.header .banner2 .menu .item h3 a {
    display: block;
    padding: 15px 20px;
}
.header .banner2 .menu .item h3,
.header .banner2 .menu .item h3 a {
    margin: 0;
}
.header .banner2 .menu .item h3,
.header .banner2 .menu .item h3 a {
    color: #f5f8fb;
    font-size: 13px;
    position: relative;
}
.header .banner2 .menu .item.drop h3::after {
    border-bottom: 2px solid #f5f8fb;
    border-left: 2px solid #f5f8fb;
    content: '';
    display: block;
    height: 6px;
    position: absolute;
    right: 28px;
    top: 15px;
    transform: rotate(-45deg);
    width: 6px;
}
.header .banner2 .menu .item.drop.open h3::after {
    top: 19px;
    transform: rotate(135deg);
}
.header .banner2 .menu .item.drop ul {
    display: none;
}
.header .banner2 .menu .item.drop.open ul {
    display: block;
}
.header .banner2 .menu .item.drop ul li {
    margin: 0;
}
.header .banner2 .menu .item.drop ul li a {
    color: #fff;
    display: block;
    font-size: 13px;
    margin: 0;
    padding: 15px 20px;
}
.header .banner2 .logo {
    align-items: center;
    display: inline-flex;
    height: 50px;
    justify-content: center;
    padding: 10px 0;
    vertical-align: top;
    width: calc(100% - 55px);
}
.header .banner2 .logo a {
    display: inline-block;
    line-height: 0;
}
.header .banner2 .logo a > svg {
    height: 25px;
    width: 25px;
}
.header .banner2 .logo .text-logo {
    display: inline-block;
    padding: 3px 0 3px 10px;
    position: relative;
    vertical-align: top;
}
.header .banner2 .logo .text-logo svg {
    width: 140px;
}
.header .logo .text-logo span {
    background: #ed2224;;
    border-radius: 4px;
    bottom: 10px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
    padding: 2px 5px;
    position: absolute;
    left: calc(100% - -15px);
}
.header .banner2 .cart {
    display: inline-block;
    height: 50px;
    padding: 14px 0;
    text-align: center;
    vertical-align: top;
    width: 30px;
}
.header .banner2 .cart a {
    display: inline-block;
}
.header .banner2 .cart svg {
    fill: rgba(245, 248, 251, 0.8);
    display: inline-block;
    height: 22px;
    width: 22px;
}
.header .banner2 .cart .cart-size {
    background: #0175a2;
    border-radius: 50%;
    color: #fff;
    font-size: 9px;
    font-weight: 400;
    height: 14px;
    line-height: 10px;
    padding: 2px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 10px;
    width: 14px;
}
.header .bt-banner {
    background: #000;
    bottom: 0;
    height: 40px;
    left: 0;
    position: fixed;
    width: 100%;
}
.header + .header-spacer {
    height: 50px;
    margin: 0 0 10px;
    width: 100%;
}

@media only screen and (min-width: 64em) {
    body.hb-open {
        overflow: auto;
    }
    .header {
        top: -100px;
    }
    .header .banner1 {
        background-color: #100e17;
        display: block;
        height: 60px;
        width: 100%;
    }
    .header .banner1 .logo {
        display: inline-block;
        height: 60px;
        padding: 18px 0;
        vertical-align: top;
        width: calc(100% - 200px);
    }
    .header .banner1 .logo a {
        display: inline-block;
        line-height: 0;
    }
    .header .banner1 .logo a > svg {
        height: 30px;
        width: 30px;
    }
    .header .banner1 .logo .text-logo {
        display: inline-block;
        padding: 0 0 0 20px;
        position: relative;
        vertical-align: top;
    }
    .header .banner1 .logo .text-logo svg {
        width: 200px;
    }
    .header .logo .text-logo span {
        font-size: 19px;
    }
    .no-touch .header .banner1 .logo:hover .text-logo svg {
        fill: red;
    }
    .header .banner1 .logo .content p {
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 3px;
    }
    .header .banner1 .account,
    .header .banner1 .support,
    .header .banner1 .cart {
        align-items: center;
        display: inline-flex;
        height: 60px;
        line-height: 0;
        text-align: center;
        vertical-align: top;
        width: 60px;
    }
    .header .banner1 .account a,
    .header .banner1 .support a,
    .header .banner1 .cart a {
        width: 100%;
    }
    .header .banner1 .account > a svg,
    .header .banner1 .account > button svg,
    .header .banner1 .support svg,
    .header .banner1 .cart svg {
        display: inline-block;
        fill: rgba(245, 248, 251,0.8);
        height: 18px;
        vertical-align: top;
        width: 18px;
    }
    .no-touch .header .banner1 .account:hover > a svg,
    .no-touch .header .banner1 .account:hover > button svg,
    .no-touch .header .banner1 .support:hover svg,
    .no-touch .header .banner1 .cart:hover svg {
        fill: #db0b58;
        transform: scale(1.2);
    }
    .header .banner1 .account span,
    .header .banner1 .support span,
    .header .banner1 .cart span {
        color: rgba(245, 248, 251, 0.7);
        display: block;
        font-size: 12px;
        line-height: 20px;
    }
    .header .banner1 .cart {
        position: relative;
    }
    .header .banner1 .cart .cart-size {
        background: #0175a2;
        border-radius: 50%;
        color: #fff;
        font-size: 10px;
        font-weight: 400;
        height: 18px;
        line-height: 10px;
        padding: 4px 2px;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 5px;
        width: 18px;
    }
    .header .banner1 .account {
        position: relative;
    }
    .header .banner1 .account span {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 60px;
        overflow: hidden;
    }
    .header .banner1 .account .account-details {
        background: #1d1e23;
        display: none;
        left: calc(50%);
        padding: 12px 20px;
        position: absolute;
        top: 60px;
        transform: translateX(-50%);
        width: 200px;
        z-index: 5;
    }
    .header .banner1 .account:hover .account-details {
        box-shadow: 0px 3px 5px 1px #000;
        display: block;
    }
    .header .banner1 .account:hover .account-details::before {
        border-bottom: 8px solid #1d1e23;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        content: '';
        display: block;
        height: 0;
        left: 50%;
        position: absolute;
        top: -8px;
        transform: translateX(-50%);
        width: 0;
    }
    .header .banner1 .account .account-details .btn.signin {
        margin-bottom: 10px;
    }
    .header .banner1 .account:hover .account-details ul {
        text-align-last: left;
    }
    .header .banner1 .account:hover .account-details li {
        border-bottom: 1px solid #444;
        font-size: 14px;
        margin: 0;
        padding: 11px 5px;
    }
    .header .banner1 .account:hover .account-details li:last-child {
        border: none;
    }
    .header .banner1 .account:hover .account-details li svg {
        height: 18px;
        margin: 0 10px -2px 0;
        width: 18px;
    }
    .header .banner2 {
        height: 40px;
    }
    .header .banner2 .menu {
        height: 40px;
        width: 100%;
    }
    .header .banner2 .menu .hb-menu,
    .header.hb-open .banner2 .menu-mask,
    .header .banner2 .logo,
    .header .banner2 .cart {
        display: none;
    }
    .header .banner2 .menu .items {
        background: transparent;
        border: none;
        display: block;
        max-width: initial;
        overflow: visible;
        position: static;
        width: 100%;
    }
    .header .banner2 .menu .items,
    .header.hb-open .banner2 .menu .items {
        transform: none;
        transition: none;
    }
    .header .banner2 .menu .item {
        border: none;
        cursor: pointer;
        display: inline-block;
        height: 40px;
        position: relative;
        text-align: center;
        vertical-align: top;
        width: 20%;
    }
    .header .banner2 .menu .item.mob {
        display: none;
    }
    .header .banner2 .menu .item::before {
        border-bottom: 2px solid transparent;
        content: ' ';
        display: block;
        height: 40px;
        left: 50%;
        position: absolute;
        right: 50%;
        top: 0;
        transition: all 0.5s ease-in-out;
    }
    .touch .header .banner2 .menu .item.open::before,
    .no-touch .header .banner2 .menu .item:hover::before {
        border-bottom: 2px solid #00bceb;
        left: 0;
        right: 0;
    }
    .header .banner2 .menu .item.drop h3,
    .header .banner2 .menu .item h3 a {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.5px;
        line-height: 40px;
        padding: 0;
        text-transform: uppercase;
    }
    .no-touch .header .banner2 .menu .item:hover h3,
    .no-touch .header .banner2 .menu .item:hover h3 a {
        font-size: 13px;
    }
    .header .banner2 .menu .item.drop h3::after {
        right: 15px;
    }
    .touch .header .banner2 .menu .item.drop.open h3::after,
    .no-touch .header .banner2 .menu .item.drop:hover h3::after {
        top: 19px;
        transform: rotate(135deg);
    }
    .header .banner2 .menu .item.drop ul {
        background: #100e17;
        display: block;
        position: absolute;
        transform: translateY(-100%);
        transition: all 0.5s ease-in-out;
        width: 100%;
        z-index: -1;
    }
    .touch .header .banner2 .menu .item.drop.open ul,
    .no-touch .header .banner2 .menu .item.drop:hover ul {
        box-shadow: 0px 4px 6px 1px #000;
        transform: translateY(0);
    }
    .header .banner2 .menu .item.drop ul li {
        border-bottom: 1px solid #333;
    }
    .header .banner2 .menu .item.drop ul li:last-child {
        border: none;
    }
    .header .banner2 .menu .item.drop ul li,
    .header .banner2 .menu .item.drop ul li a {
        font-size: 15px;
        line-height: 20px;
        margin: 0;
    }
    .header .banner2 .menu .item.drop ul li a {
        color: #007493;
        display: block;
        padding: 15px;
    }
    .no-touch .header .banner2 .menu .item.drop ul li:hover a {
        color: #00bceb;
        transform: scale(1.1);
    }
    .header .bt-banner {
        display: none;
    }
    .header + .header-spacer {
        height: 100px;
        margin: 0 0 15px;
    }
}

@media only screen and (min-width: 80em) {
    .header .banner1 .logo {
        padding: 10px 0;
    }
    .header .banner1 .logo a > svg {
        height: 40px;
        width: 40px;
    }
    .header .banner1 .logo .text-logo {
        padding: 5px 0 5px 15px;
    }
    .header + .header-spacer {
        margin: 0 0 20px;
    }
}