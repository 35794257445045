.prod-list {
    padding: 10px 20px;
}
.prod-list table {
    color: #ccc;
}
.prod-list input[type="radio"] {
    width: 30px;
}
.prod-list input[type="radio"] {
    width: 30px;
}
.prod-list textarea {
    width: 100%;
}
.search-wrap {
    display: grid;
    gap: 20px;
    grid-template-columns: 150px 200px auto 200px;
}
.search-results {
    padding: 30px;
}
.prod-list .details input,
.products .details input {
    width: 500px;
}
.prod-span {
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    padding: 0 2px;
}