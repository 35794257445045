.tabs {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: flex-start;
}
.tab {
    align-self: flex-end;
    background: #1d1e23;
    border: 1px solid #4d4c4c;
    border-bottom: none;
    cursor: pointer;
    padding: 15px 30px;
}
.tab.active {
    border-top: 3px solid #dc3e58;
    padding: 20px 30px;
    z-index: 1;
}
.tab-panel {
    border: 1px solid #4d4c4c;
    display: none;
    padding: 10px;
    position: relative;
    top: -1px;
    width: 100%;
}
.tab-panel.active {
    display: block;
}