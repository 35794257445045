@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300&display=swap);
html, body, header, footer, h1, h2, h3, h4, h5, h6, ul, ol, li, p, a, span {
    margin: 0;
    padding: 0;
}
body {
    background: #1d1e23;
    color: #4d4c4c;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
}
body * {
    font: inherit;
    letter-spacing: 1px;
}
body, body * {
    box-sizing: border-box;
}
.pri-0 {
    font-size: 28px;
    line-height: 1.214em;
    margin-bottom: 1.214em;
    font-weight: 400;
}
h1,
.pri-1,
h2,
.pri-2 {
    font-size: 22px;
    line-height: 1.167em;
    margin-bottom: 1.167em;
    font-weight: 300;
}
h3,
.pri-3 {
    font-size: 20px;
    line-height: 1.2em;
    margin-bottom: 1.2em;
    font-weight: 400;
}
h4,
h5,
.pri-4,
.pri-5,
h6,
.pri-6,
p,
li {
    font-size: 16px;
    line-height: 1.375em;
    margin-bottom: 0.75em;
    font-weight: 300;
}
li,
li a {
    line-height: 1.25em;
    margin-bottom: 1.25em;
}
li a {
    font-weight: 400;
}
p.util,
.util * {
    font-size: 14px;
    line-height: 1.286em;
    margin-bottom: 1.286em;
    font-weight: 300;
}
p strong,
p.util strong {
    font-weight: 400;
}
a {
    color: #00bceb;
    text-decoration: none;
    outline: none;
}
a:hover,
a:focus {
    color: #007493;
    text-decoration: none;
}
a:active {
    color: #005073;
}
.touch a:hover,
.touch a:active {
    text-decoration: none;
}
button {
    background: #6953d2;
    border: transparent;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    display: block;
    padding: 15px 10px;
    text-transform: uppercase;
    width: 100%;
    font-size: 12px;
    min-width: 200px;
    font-weight: 500;
    padding: 20px;
}
button:hover {
    background: #4d3c9f;
}
.txt-link,
button.txt-link {
    background: none;
    border: none;
    color: #00bceb;
    cursor: pointer;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.txt-link:hover,
.txt-link:focus,
button.txt-link:hover,
button.txt-link:focus {
    color: #007493;
    text-decoration: none;
}
.txt-link:active,
button.txt-link:active {
    color: #005073;
}
ul,
ol {
    margin-bottom: 1.429em;
}
ul                 { list-style-type: disc; }
ul.no-bullets      { list-style-type: none; }
ol                 { list-style-type: decimal; }
ol.u-alpha {
    list-style-type: upper-alpha;
}
ol.l-alpha {
    list-style-type: lower-alpha;
}
ol.u-roman {
    list-style-type: upper-roman;
}
ol.l-roman {
    list-style-type: lower-roman;
}
ul li              { margin-left: 17px; }
ul.no-bullets li   { margin-left: 0; }
ol li              { margin-left: 25px; }
ul.bullets {
    list-style-type: none;
}
ul.bullets li {
    position: relative;
}
ul.bullets li::before {
    border-bottom: 4px solid transparent;
    border-left: 6px solid;
    border-top: 4px solid transparent;
    content: '';
    display: block;
    height: 0;
    left: -15px;
    top: 4px;
    position: absolute;
    width: 0;
}
strong {
    font-weight: 400;
}
.txt-blue {
    color: #00bceb;
}
.txt-red {
    color: #db0b5b;
}
.txt-white {
    color: #ffffff;
}
.txt-light {
    color: #f6f1ed;
}
.txt-c-grey {
    color: #cccccc;
}
.txt-light-grey {
    color: #aaa;
}
.txt-grey {
    color: #737e92;
}
.txt-dark-grey {
    color: #565656;
}
.txt-dark-head {
    color: #564f6f;
}
.txt-dark {
    color: #000;
}
.cap {
    letter-spacing: 2px;
    text-transform: uppercase;
}
.ls-4 {
    letter-spacing: 4px;
}
.btn {
    background: transparent;
    border-radius: 100px;
    cursor: pointer;
    display: block;
    font-size: 13px;
    line-height: 1.25em;
    margin: 0;
    min-width: 140px;
    padding: 5px 20px 6px;
    text-align: center;
    width: 100%;
}
.btn:hover {
    text-decoration: none;
}
.btn.pri0 {
    border: 2px solid #db0b5b;
    color: #f6f1ed;
}
.btn.pri0.solid {
    background: #db0b5b;
    color: #fff;
    position: relative;
    font-weight: 600;
}
.btn.pri0.solid :hover {
    background-color: #33c9ef;
    border: 2px solid #33c9ef;
    color: #f6f1ed;
}
.btn.pri0.solid :active {
    background-color: #66d6f2;
    border: 2px solid #66d6f2;
    color: #f6f1ed;
}
.btn.pri0.solid :focus {
    box-shadow: 0 0 8px 0 #00bceb;
}
.btn.icon svg {
    margin: 0 10px -6px 0;
    width: 15px;
}
.btn.pri0.icon svg {
    fill: #f6f1ed;
}
.btn.pri1 {
    border: 2px solid #00bceb;
    color: #00bceb;
}
.btn.pri1.solid {
    background-color: #00bceb;
    color: #000;
    font-weight: 600;
}
.btn.pri1.solid :hover {
    background-color: #0175a2;
    border: 2px solid #0175a2;
    color: #f6f1ed;
}
.btn.pri1.solid :active {
    background-color: #0175a2;
    border: 2px solid #0175a2;
    color: #f6f1ed;
}
.btn.pri1.solid :focus {
    box-shadow: 0 0 8px 0 #0175a2;
}
.btn.pri1.icon svg {
    fill: #00bceb;
}
.btn.pri1.solid.icon svg {
    fill: #000;
}
.btn.light {
    border: 2px solid #f6f1ed;
    color: #00bceb;
}
.btn.light:hover {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #00bceb;
}
.btn.light:active {
    background-color: #ffffff;
    border: 2px solid #ffffff;
    color: #00bceb;
}
.btn.light:focus {
    box-shadow: 0 0 8px 0 #f6f1ed;
}
.btn.light.icon svg {
    fill: #00bceb;
}
.btn.disabled,
.btn.disabled:hover,
.btn.disabled:focus {
    border: 2px solid transparent;
    background: #4a4a4a;
    color: #000;
}
.text-link {
    color: #00bceb;
    cursor: pointer;
    display: inline-block;
    font-weight: 400;
}
.btns li {
    display: block;
    margin: 0 0 20px;
}
:last-child {
    margin-bottom: 0;
}
.compact {
    margin-bottom: 0;
}

/*  Small   = 600 to 767 pixels */
@media only screen and (min-width: 37.5em) {
    .pri-0 {
        font-size: 36px;
        line-height: 1.222em;
        margin-bottom: 1.222em;
    }
    h1,
    .pri-1,
    h2,
    .pri-2 {
        font-size: 28px;
        line-height: 1.214em;
        margin-bottom: 1.214em;
        font-weight: 200;
    }
    h3,
    h3 i,
    h3 em,
    .pri-3,
    .pri-3 i,
    .pri-3 em {
        font-size: 22px;
        line-height: 1.273em;
        margin-bottom: 1.273em;
    }
    h4,
    .pri-4 {
        font-size: 20px;
        line-height: 1.2em;
        margin-bottom: 1.2em;
    }
    h6,
    .pri-6 {
        line-height: 1.5em;
        font-weight: 400;
    }
    p {
        line-height: 1.5em;
    }
    .btn {
        display: inline-block;
        width: auto;
    }
    .btns li {
        display: inline-block;
        margin: 0 20px 20px 0;
    }
    .btns li:last-child {
        margin: 0 0 20px 0;
    }
}

/* Medium  = 768 to 959 pixels */
@media only screen and (min-width: 48em) {
    .pri-0 {
        font-size: 46px;
        line-height: 1.1875em;
        margin-bottom: 1.1875em;
    }
    h1,
    .pri-1 {
        font-size: 36px;
        line-height: 1.217em;
        margin-bottom: 1.217em;
    }
    h2,
    .pri-2 {
        font-size: 32px;
        line-height: 1.4375em;
        margin-bottom: 0.875em;
    }
    h3,
    h3 i,
    h3 em,
    .pri-3,
    .pri-3 i,
    .pri-3 em {
        font-size: 24px;
        line-height: 1.286em;
        margin-bottom: 1em;
    }
    h4,
    .pri-4 {
        font-size: 22px;
        line-height: 1.27em;
        margin-bottom: 0.5em;
    }
    h5,
    .pri-5 {
        font-size: 18px;
        line-height: 1.27em;
        margin-bottom: 0.56em;
        font-weight: 400;
    }
    p.util,
    .util * {
        font-size: 14px;
        line-height: 1.429em;
        margin-bottom: 1.429em;
    }
}
select, input{
    width: 100%;
    height: 25px;
}
table{
    width: 100%;
}
.background{
    border: 2px solid #fff;
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
    background-color: rgb(20, 19, 19);
}
.prod-list {
    padding: 10px 20px;
}
.prod-list table {
    color: #ccc;
}
.prod-list input[type="radio"] {
    width: 30px;
}
.prod-list input[type="radio"] {
    width: 30px;
}
.prod-list textarea {
    width: 100%;
}
.search-wrap {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: 150px 200px auto 200px;
}
.search-results {
    padding: 30px;
}
.prod-list .details input,
.products .details input {
    width: 500px;
}
.prod-span {
    cursor: pointer;
    display: inline-block;
    line-height: 0;
    padding: 0 2px;
}
.tabs {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: flex-start;
}
.tab {
    align-self: flex-end;
    background: #1d1e23;
    border: 1px solid #4d4c4c;
    border-bottom: none;
    cursor: pointer;
    padding: 15px 30px;
}
.tab.active {
    border-top: 3px solid #dc3e58;
    padding: 20px 30px;
    z-index: 1;
}
.tab-panel {
    border: 1px solid #4d4c4c;
    display: none;
    padding: 10px;
    position: relative;
    top: -1px;
    width: 100%;
}
.tab-panel.active {
    display: block;
}
table {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    margin-bottom: 20px;
}
table td {
    border-bottom: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 5px;
}
table td input.small,
table td select.small {
    width: 100px;
}
table td input.large,
table td select.large,
table td textarea.large {
    width: 300px;
}
table.even-odd tr:nth-child(2n+1) {
    background: #999;
    color: #000;
}
.order {
    margin-bottom: 50px;
}
.orders-list .order {
    background: #000;
    font-size: 14px;
    padding: 10px;
}
.core-comps {
    margin-bottom: 40px;
}
.core-comps .comp-wrap {
    width: 100%;
}
.core-comps .comp-wrap .back {
    display: none;
}
.core-comps .components {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
}
.core-comps .components .comp {
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    cursor: pointer;
    padding: 18px 12px;
    text-align: center;
}
.core-comps.prod .components .comp {
    display: none;
}
.core-comps.prod .components .comp.current {
    display: flex;
}
.core-comps .components .comp .med,
.core-comps .components .comp .cnt {
    width: 100%;
}
.core-comps .components .comp .med {
    margin: 0 0 10px;
}
.core-comps .components .comp .med svg {
    fill: #0175a2;
    height: 50px;
    width: 50px;
}
.core-comps .components .comp .cnt h2 {
    line-height: 20px;
}
.core-comps .prod-wrap {
    display: none;
    width: 100%;
}
.core-comps .filters {
    position: relative;
    width: 100%;
}
.core-comps .filters h3 {
    border: 1px solid;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px 40px 10px 20px;
    position: relative;
}
.core-comps .filters h3::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid;
    content: '';
    display: block;
    position: absolute;
    right: 15px;
    top: 18px;
}
.core-comps .filters.open h3::after {
    transform: rotate(180deg);
}
.core-comps .filters .flt {
    background: #1d1e23;
    border: 1px solid;
    border-radius: 10px;
    display: none;
    padding: 10px 0;
    position: absolute;
    top: 50px;
    width: 100%;
}
.core-comps .filters.open .flt {
    display: block;
}
.core-comps .filters .flt li {
    cursor: pointer;
    margin: 0;
    padding: 10px 20px 10px 50px;
    position: relative;
    width: 100%;
}
.core-comps .filters .flt li::before {
    border: 1px solid;
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    left: 20px;
    position: absolute;
    top: 12px;
    width: 16px;
}
.core-comps .filters .flt li.active::after {
    background: #00bceb;
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    left: 24px;
    position: absolute;
    top: 16px;
    width: 10px;
}
.core-comps .products {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}
.core-comps .products .comp {
    align-items: center;
    border: 1px solid;
    border-radius: 10px;
    display: flex;
    height: 100px;
    text-align: center;
}

@media only screen and (min-width: 48em) {
    .core-comps .components {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (min-width: 64em) {
    .core-comps .components {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media only screen and (min-width: 80em) {
    .core-comps .components {
        grid-template-columns: repeat(10, 1fr);
    }
}
body.hb-open {
    overflow: hidden;
}
.header {
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.5);
    left: 0;
    position: fixed;
    top: -50px;
    transition: top 0.3s ease-in-out;
    width: 100%;
    z-index: 10;
}
.header.hb-open,
.header.sticky {
    top: 0;
}
.header .banner1 {
    display: none;
}
.header .banner2 {
    background-color: #100e17;
    font-size: 0;
    height: 50px;
    width: 100%;
}
.header .banner2 .menu {
    display: inline-block;
    height: 50px;
    width: 25px;
}
.header .banner2 .menu .hb-menu {
    height: 50px;
    padding: 14.5px 0;
    position: absolute;
    left: 20px;
    top: 0;
    width: 25px;
}
.header .banner2 .menu .hb-menu span,
.header .banner2 .menu .hb-menu span:before,
.header .banner2 .menu .hb-menu span:after {
    background: #0175a2;
    border-radius: 10px;
    display: block;
    height: 3px;
    width: 25px;
}
.header .banner2 .menu .hb-menu span {
    position: relative;
    top: 8px;
    transition: transform 0.25s ease-in-out 0.15s;
}
.header.hb-open .banner2 .menu .hb-menu span {
    transform: rotate(-45deg);
}
.header .banner2 .menu .hb-menu span:before {
    content: '';
    position: absolute;
    top: -8px;
    transition: top 0.15s ease-in-out, transform 0.25s ease-in-out 0.15s;
}
.header .banner2 .menu .hb-menu span:after {
    content: '';
    position: absolute;
    top: 8px;
    transition: top 0.15s ease-in-out, transform 0.25s ease-in-out 0.15s;
}
.header.hb-open .banner2 .menu .hb-menu span:before,
.header.hb-open .banner2 .menu .hb-menu span:after {
    top: 0;
    transform: rotate(90deg);
}
.header.hb-open .banner2 .menu-mask {
    background: rgba(0,0,0,0.3);
    bottom: 0;
    content: '';
    left: 0;
    pointer-events: auto;
    position: fixed;
    right: 0;
    top: 40px;
}
.header .banner2 .menu .items {
    background: #1d1e23;
    border-top: 3px solid #0175a2;
    bottom: 40px;
    left: 0;
    max-width: 300px;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 50px;
    transform: translateX(-100%);
    transition: all 0.3s ease-in;
    width: 70%;
}
.header.hb-open .banner2 .menu .items {
    transform: translateX(0);
}
.header .banner2 .menu .item {
    border-bottom: 1px solid #666;
    position: relative;
    width: 100%;
}
.header .banner2 .menu .item.drop h3,
.header .banner2 .menu .item h3 a {
    display: block;
    padding: 15px 20px;
}
.header .banner2 .menu .item h3,
.header .banner2 .menu .item h3 a {
    margin: 0;
}
.header .banner2 .menu .item h3,
.header .banner2 .menu .item h3 a {
    color: #f5f8fb;
    font-size: 13px;
    position: relative;
}
.header .banner2 .menu .item.drop h3::after {
    border-bottom: 2px solid #f5f8fb;
    border-left: 2px solid #f5f8fb;
    content: '';
    display: block;
    height: 6px;
    position: absolute;
    right: 28px;
    top: 15px;
    transform: rotate(-45deg);
    width: 6px;
}
.header .banner2 .menu .item.drop.open h3::after {
    top: 19px;
    transform: rotate(135deg);
}
.header .banner2 .menu .item.drop ul {
    display: none;
}
.header .banner2 .menu .item.drop.open ul {
    display: block;
}
.header .banner2 .menu .item.drop ul li {
    margin: 0;
}
.header .banner2 .menu .item.drop ul li a {
    color: #fff;
    display: block;
    font-size: 13px;
    margin: 0;
    padding: 15px 20px;
}
.header .banner2 .logo {
    align-items: center;
    display: inline-flex;
    height: 50px;
    justify-content: center;
    padding: 10px 0;
    vertical-align: top;
    width: calc(100% - 55px);
}
.header .banner2 .logo a {
    display: inline-block;
    line-height: 0;
}
.header .banner2 .logo a > svg {
    height: 25px;
    width: 25px;
}
.header .banner2 .logo .text-logo {
    display: inline-block;
    padding: 3px 0 3px 10px;
    position: relative;
    vertical-align: top;
}
.header .banner2 .logo .text-logo svg {
    width: 140px;
}
.header .logo .text-logo span {
    background: #ed2224;;
    border-radius: 4px;
    bottom: 10px;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
    padding: 2px 5px;
    position: absolute;
    left: calc(100% - -15px);
}
.header .banner2 .cart {
    display: inline-block;
    height: 50px;
    padding: 14px 0;
    text-align: center;
    vertical-align: top;
    width: 30px;
}
.header .banner2 .cart a {
    display: inline-block;
}
.header .banner2 .cart svg {
    fill: rgba(245, 248, 251, 0.8);
    display: inline-block;
    height: 22px;
    width: 22px;
}
.header .banner2 .cart .cart-size {
    background: #0175a2;
    border-radius: 50%;
    color: #fff;
    font-size: 9px;
    font-weight: 400;
    height: 14px;
    line-height: 10px;
    padding: 2px;
    position: absolute;
    right: 15px;
    text-align: center;
    top: 10px;
    width: 14px;
}
.header .bt-banner {
    background: #000;
    bottom: 0;
    height: 40px;
    left: 0;
    position: fixed;
    width: 100%;
}
.header + .header-spacer {
    height: 50px;
    margin: 0 0 10px;
    width: 100%;
}

@media only screen and (min-width: 64em) {
    body.hb-open {
        overflow: auto;
    }
    .header {
        top: -100px;
    }
    .header .banner1 {
        background-color: #100e17;
        display: block;
        height: 60px;
        width: 100%;
    }
    .header .banner1 .logo {
        display: inline-block;
        height: 60px;
        padding: 18px 0;
        vertical-align: top;
        width: calc(100% - 200px);
    }
    .header .banner1 .logo a {
        display: inline-block;
        line-height: 0;
    }
    .header .banner1 .logo a > svg {
        height: 30px;
        width: 30px;
    }
    .header .banner1 .logo .text-logo {
        display: inline-block;
        padding: 0 0 0 20px;
        position: relative;
        vertical-align: top;
    }
    .header .banner1 .logo .text-logo svg {
        width: 200px;
    }
    .header .logo .text-logo span {
        font-size: 19px;
    }
    .no-touch .header .banner1 .logo:hover .text-logo svg {
        fill: red;
    }
    .header .banner1 .logo .content p {
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 3px;
    }
    .header .banner1 .account,
    .header .banner1 .support,
    .header .banner1 .cart {
        align-items: center;
        display: inline-flex;
        height: 60px;
        line-height: 0;
        text-align: center;
        vertical-align: top;
        width: 60px;
    }
    .header .banner1 .account a,
    .header .banner1 .support a,
    .header .banner1 .cart a {
        width: 100%;
    }
    .header .banner1 .account > a svg,
    .header .banner1 .account > button svg,
    .header .banner1 .support svg,
    .header .banner1 .cart svg {
        display: inline-block;
        fill: rgba(245, 248, 251,0.8);
        height: 18px;
        vertical-align: top;
        width: 18px;
    }
    .no-touch .header .banner1 .account:hover > a svg,
    .no-touch .header .banner1 .account:hover > button svg,
    .no-touch .header .banner1 .support:hover svg,
    .no-touch .header .banner1 .cart:hover svg {
        fill: #db0b58;
        transform: scale(1.2);
    }
    .header .banner1 .account span,
    .header .banner1 .support span,
    .header .banner1 .cart span {
        color: rgba(245, 248, 251, 0.7);
        display: block;
        font-size: 12px;
        line-height: 20px;
    }
    .header .banner1 .cart {
        position: relative;
    }
    .header .banner1 .cart .cart-size {
        background: #0175a2;
        border-radius: 50%;
        color: #fff;
        font-size: 10px;
        font-weight: 400;
        height: 18px;
        line-height: 10px;
        padding: 4px 2px;
        position: absolute;
        right: 10px;
        text-align: center;
        top: 5px;
        width: 18px;
    }
    .header .banner1 .account {
        position: relative;
    }
    .header .banner1 .account span {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 60px;
        overflow: hidden;
    }
    .header .banner1 .account .account-details {
        background: #1d1e23;
        display: none;
        left: calc(50%);
        padding: 12px 20px;
        position: absolute;
        top: 60px;
        transform: translateX(-50%);
        width: 200px;
        z-index: 5;
    }
    .header .banner1 .account:hover .account-details {
        box-shadow: 0px 3px 5px 1px #000;
        display: block;
    }
    .header .banner1 .account:hover .account-details::before {
        border-bottom: 8px solid #1d1e23;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        content: '';
        display: block;
        height: 0;
        left: 50%;
        position: absolute;
        top: -8px;
        transform: translateX(-50%);
        width: 0;
    }
    .header .banner1 .account .account-details .btn.signin {
        margin-bottom: 10px;
    }
    .header .banner1 .account:hover .account-details ul {
        text-align-last: left;
    }
    .header .banner1 .account:hover .account-details li {
        border-bottom: 1px solid #444;
        font-size: 14px;
        margin: 0;
        padding: 11px 5px;
    }
    .header .banner1 .account:hover .account-details li:last-child {
        border: none;
    }
    .header .banner1 .account:hover .account-details li svg {
        height: 18px;
        margin: 0 10px -2px 0;
        width: 18px;
    }
    .header .banner2 {
        height: 40px;
    }
    .header .banner2 .menu {
        height: 40px;
        width: 100%;
    }
    .header .banner2 .menu .hb-menu,
    .header.hb-open .banner2 .menu-mask,
    .header .banner2 .logo,
    .header .banner2 .cart {
        display: none;
    }
    .header .banner2 .menu .items {
        background: transparent;
        border: none;
        display: block;
        max-width: none;
        max-width: initial;
        overflow: visible;
        position: static;
        width: 100%;
    }
    .header .banner2 .menu .items,
    .header.hb-open .banner2 .menu .items {
        transform: none;
        transition: none;
    }
    .header .banner2 .menu .item {
        border: none;
        cursor: pointer;
        display: inline-block;
        height: 40px;
        position: relative;
        text-align: center;
        vertical-align: top;
        width: 20%;
    }
    .header .banner2 .menu .item.mob {
        display: none;
    }
    .header .banner2 .menu .item::before {
        border-bottom: 2px solid transparent;
        content: ' ';
        display: block;
        height: 40px;
        left: 50%;
        position: absolute;
        right: 50%;
        top: 0;
        transition: all 0.5s ease-in-out;
    }
    .touch .header .banner2 .menu .item.open::before,
    .no-touch .header .banner2 .menu .item:hover::before {
        border-bottom: 2px solid #00bceb;
        left: 0;
        right: 0;
    }
    .header .banner2 .menu .item.drop h3,
    .header .banner2 .menu .item h3 a {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 1.5px;
        line-height: 40px;
        padding: 0;
        text-transform: uppercase;
    }
    .no-touch .header .banner2 .menu .item:hover h3,
    .no-touch .header .banner2 .menu .item:hover h3 a {
        font-size: 13px;
    }
    .header .banner2 .menu .item.drop h3::after {
        right: 15px;
    }
    .touch .header .banner2 .menu .item.drop.open h3::after,
    .no-touch .header .banner2 .menu .item.drop:hover h3::after {
        top: 19px;
        transform: rotate(135deg);
    }
    .header .banner2 .menu .item.drop ul {
        background: #100e17;
        display: block;
        position: absolute;
        transform: translateY(-100%);
        transition: all 0.5s ease-in-out;
        width: 100%;
        z-index: -1;
    }
    .touch .header .banner2 .menu .item.drop.open ul,
    .no-touch .header .banner2 .menu .item.drop:hover ul {
        box-shadow: 0px 4px 6px 1px #000;
        transform: translateY(0);
    }
    .header .banner2 .menu .item.drop ul li {
        border-bottom: 1px solid #333;
    }
    .header .banner2 .menu .item.drop ul li:last-child {
        border: none;
    }
    .header .banner2 .menu .item.drop ul li,
    .header .banner2 .menu .item.drop ul li a {
        font-size: 15px;
        line-height: 20px;
        margin: 0;
    }
    .header .banner2 .menu .item.drop ul li a {
        color: #007493;
        display: block;
        padding: 15px;
    }
    .no-touch .header .banner2 .menu .item.drop ul li:hover a {
        color: #00bceb;
        transform: scale(1.1);
    }
    .header .bt-banner {
        display: none;
    }
    .header + .header-spacer {
        height: 100px;
        margin: 0 0 15px;
    }
}

@media only screen and (min-width: 80em) {
    .header .banner1 .logo {
        padding: 10px 0;
    }
    .header .banner1 .logo a > svg {
        height: 40px;
        width: 40px;
    }
    .header .banner1 .logo .text-logo {
        padding: 5px 0 5px 15px;
    }
    .header + .header-spacer {
        margin: 0 0 20px;
    }
}
.footer {
    width: 100%;
}
.footer .fat-footer {
    background: #061213;
    padding: 30px 0;
    width: 100%;
}
.footer .fat-footer .footer-info {
    border-bottom: 1px solid #4d4c4c;
    display: grid;
    grid-gap: 40px;
    gap: 40px;
    grid-template-columns: 1fr;
    margin: 0 auto 25px;
    padding-bottom: 25px;
}
.footer .fat-footer .footer-info h4,
.footer .fat-footer .footer-info p {
    color: #ccc;
}
.footer .fat-footer .footer-info h4 {
    font-size: 16px;
}
.footer .fat-footer .footer-info p {
    font-size: 14px;
}
.footer .fat-footer .follow-us {
    text-align: center;
}
.footer .fat-footer .follow-us h6 {
    color: rgba(208,208,208,0.8);
    font-size: 14px;
    margin-bottom: 10px;
}
.footer .fat-footer .follow-us .social-links {
    display: inline-grid;
    grid-gap: 15px;
    gap: 15px;
    grid-template-columns: repeat(5, 1fr);
}
.footer .fat-footer .follow-us .social-links a {
    line-height: 0;
}
.footer .fat-footer .follow-us .social-links a svg path {
    fill: #727272;
}
.footer .fat-footer .follow-us .social-links a:hover svg path {
    fill: #0175a2;
}
.footer .fat-footer .follow-us .social-links a:last-child {
    margin-right: 0;
}
.footer .fat-footer .follow-us .social-links a .fa {
    font-size: 25px;
}
.footer .fat-footer .footer-links {
    display: grid;
    grid-gap: 25px;
    gap: 25px;
    grid-template-columns: 1fr;
    width: 100%;
}
.footer .fat-footer .footer-links .ft-col {
    width: 100%;
}
.footer .fat-footer .footer-links .ft-title {
    color: #6495ed;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    margin: 0 0 10px;
    text-transform: uppercase;
}
.footer .fat-footer .footer-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer .fat-footer .footer-links ul li {
    margin: 0;
}
.footer .fat-footer .footer-links ul li a {
    color: #ccc;
    display: inline-block;
    font-size: 14px;
    margin: 0 0 8px;
    text-decoration: none;
}
.footer .legal-footer {
    background: #000;
    width: 100%;
}
.footer .legal-footer .footer-links {
    padding-bottom: 30px;
    padding-top: 30px;
}
.footer .legal-footer .footer-links .ft-cnt {
    display: flex;
    flex-direction: column-reverse;
}
.footer .legal-footer .rights-content {
    margin: 0 0 30px;
    text-align: center;
}
.footer .legal-footer .logo {
    margin: 0 0 30px;
    text-align: center;
}
.footer .legal-footer .logo svg {
    height: 60px;
    width: 60px;
}
.footer .legal-footer p,
.footer .legal-footer a {
    color: #727272;
}
.footer .disclaimer p {
    font-size: 12px;
    text-align: center;
}

@media only screen and (min-width: 37.5em) {
    .footer .fat-footer .footer-info {
        grid-template-columns: 1fr 1fr;
    }
    .footer .fat-footer .ft-contacts {
        justify-self: end;
    }
    .footer .fat-footer .follow-us {
        text-align: left;
    }
    .footer .fat-footer .footer-links {
        grid-template-columns: repeat(3, 1fr);
    }
    .footer .legal-footer .footer-links .ft-cnt {
        flex-direction: row;
        margin-bottom: 20px;
    }
    .footer .legal-footer .rights-content,
    .footer .legal-footer .logo {
        margin: 0;
        width: calc(100% / 3);
    }
    .footer .legal-footer .rights-content {
        align-self: flex-end;
        text-align: left;
    }
}

@media only screen and (min-width: 48em) {
    .footer .fat-footer {
        padding: 50px 0;
    }
    .footer .fat-footer .footer-info {
        grid-gap: 100px;
        gap: 100px;
        padding-bottom: 50px;
    }
}

@media only screen and (min-width: 64em) {
    .footer {
        margin-bottom: 0;
    }
    .footer .fat-footer {
        padding: 80px 0;
    }
    .footer .fat-footer .footer-info {
        margin: 0 auto 40px;
        padding-bottom: 40px;
    }
    .footer .fat-footer .footer-links {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media only screen and (min-width: 80em) {
    .footer .fat-footer .footer-info {
        grid-gap: 180px;
        gap: 180px;
    }
}
.main,
.main * {
    box-sizing: border-box;
}
.main {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 245px);
}
.mx-width {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px;
    width: 100%;
}
.page-title {
    margin: 0 0 20px;
}
.page-title.txt-center {
    text-align: center;
}
.page-title h1 {
    color: rgba(0, 188, 235, 0.8);
}
.cnt-wrap .text {
    margin: 0 0 30px;
}
svg.mail,
svg.phone,
svg.discuss {
    fill: rgba(0, 188, 235, 0.8);
    margin: 0 8px -2px 0;
}
svg.new-win {
    fill: #0175a2;
    margin: 0 0 -2px 8px;
}
.wa-chat {
    bottom: 50px;
    line-height: 0;
    position: fixed;
    right: 30px;
    z-index: 2;
}
.wa-chat a {
    background: #60d66a;
    border-radius: 50%;
    display: inline-block;
    line-height: 0;
}
.wa-chat a:hover {
    box-shadow: 0px 0px 4px 3px #666;
}
.wa-chat a svg {
    height: 50px;
    width: 50px;
}
.nav.arrow {
    height: 40px;
    padding: 8px 4px;
    width: 40px;
}
.nav.prev,
.nav.next {
    cursor: pointer;
}
.nav.arrow::before {
    border-bottom: 7px solid #4d4c4c;
    border-right: 7px solid #4d4c4c;
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    transform: rotate(-45deg);
}
.nav.arrow.prev::before {
    transform: rotate(135deg);
}
.home-bg {
    width: 34%;
    height: 67%;
    background: #282b34;
    transform: skewY(-197deg);
    position: absolute;
    top: 323px;
    right: 202px;
    border-radius: 5%;
    animation-name: kadulu;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;
    box-shadow: 3px 34px 1px 2px #222;
}
.msg.error,
.msg.warning {
    background-color: #000;
    border-radius: 5px;
    min-width: 350px;
    margin: 0 auto 10px;
    padding: 10px;
    display: inline-block;
}
.msg.error p,
.msg.warning p,
.msg.warning ul {
    color: #b0b0b0;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    width: 100%;
}
.msg.error svg,
.msg.warning svg {
    margin: 0 20px 0 0;
    vertical-align: top;
    width: 24px;
}
.msg.warning ul li {
    display: inline-block;
}
.pre-loader {
    display: inline-block;
    margin-top: 30px;
    width: 80px;
    height: 80px;
}
.pre-loader:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #00bceb;
    border-color: #00bceb transparent #00bceb transparent;
    animation: preloader 1.2s linear infinite;
}
@keyframes preloader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (min-width: 64em) {
    .mx-width {
        padding: 0 20px;
    }
}
