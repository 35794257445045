.core-comps {
    margin-bottom: 40px;
}
.core-comps .comp-wrap {
    width: 100%;
}
.core-comps .comp-wrap .back {
    display: none;
}
.core-comps .components {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
}
.core-comps .components .comp {
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 10px;
    cursor: pointer;
    padding: 18px 12px;
    text-align: center;
}
.core-comps.prod .components .comp {
    display: none;
}
.core-comps.prod .components .comp.current {
    display: flex;
}
.core-comps .components .comp .med,
.core-comps .components .comp .cnt {
    width: 100%;
}
.core-comps .components .comp .med {
    margin: 0 0 10px;
}
.core-comps .components .comp .med svg {
    fill: #0175a2;
    height: 50px;
    width: 50px;
}
.core-comps .components .comp .cnt h2 {
    line-height: 20px;
}
.core-comps .prod-wrap {
    display: none;
    width: 100%;
}
.core-comps .filters {
    position: relative;
    width: 100%;
}
.core-comps .filters h3 {
    border: 1px solid;
    border-radius: 10px;
    cursor: pointer;
    padding: 10px 40px 10px 20px;
    position: relative;
}
.core-comps .filters h3::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid;
    content: '';
    display: block;
    position: absolute;
    right: 15px;
    top: 18px;
}
.core-comps .filters.open h3::after {
    transform: rotate(180deg);
}
.core-comps .filters .flt {
    background: #1d1e23;
    border: 1px solid;
    border-radius: 10px;
    display: none;
    padding: 10px 0;
    position: absolute;
    top: 50px;
    width: 100%;
}
.core-comps .filters.open .flt {
    display: block;
}
.core-comps .filters .flt li {
    cursor: pointer;
    margin: 0;
    padding: 10px 20px 10px 50px;
    position: relative;
    width: 100%;
}
.core-comps .filters .flt li::before {
    border: 1px solid;
    border-radius: 50%;
    content: '';
    display: block;
    height: 16px;
    left: 20px;
    position: absolute;
    top: 12px;
    width: 16px;
}
.core-comps .filters .flt li.active::after {
    background: #00bceb;
    border-radius: 50%;
    content: '';
    display: block;
    height: 10px;
    left: 24px;
    position: absolute;
    top: 16px;
    width: 10px;
}
.core-comps .products {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    width: 100%;
}
.core-comps .products .comp {
    align-items: center;
    border: 1px solid;
    border-radius: 10px;
    display: flex;
    height: 100px;
    text-align: center;
}

@media only screen and (min-width: 48em) {
    .core-comps .components {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (min-width: 64em) {
    .core-comps .components {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media only screen and (min-width: 80em) {
    .core-comps .components {
        grid-template-columns: repeat(10, 1fr);
    }
}