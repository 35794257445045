.footer {
    width: 100%;
}
.footer .fat-footer {
    background: #061213;
    padding: 30px 0;
    width: 100%;
}
.footer .fat-footer .footer-info {
    border-bottom: 1px solid #4d4c4c;
    display: grid;
    gap: 40px;
    grid-template-columns: 1fr;
    margin: 0 auto 25px;
    padding-bottom: 25px;
}
.footer .fat-footer .footer-info h4,
.footer .fat-footer .footer-info p {
    color: #ccc;
}
.footer .fat-footer .footer-info h4 {
    font-size: 16px;
}
.footer .fat-footer .footer-info p {
    font-size: 14px;
}
.footer .fat-footer .follow-us {
    text-align: center;
}
.footer .fat-footer .follow-us h6 {
    color: rgba(208,208,208,0.8);
    font-size: 14px;
    margin-bottom: 10px;
}
.footer .fat-footer .follow-us .social-links {
    display: inline-grid;
    gap: 15px;
    grid-template-columns: repeat(5, 1fr);
}
.footer .fat-footer .follow-us .social-links a {
    line-height: 0;
}
.footer .fat-footer .follow-us .social-links a svg path {
    fill: #727272;
}
.footer .fat-footer .follow-us .social-links a:hover svg path {
    fill: #0175a2;
}
.footer .fat-footer .follow-us .social-links a:last-child {
    margin-right: 0;
}
.footer .fat-footer .follow-us .social-links a .fa {
    font-size: 25px;
}
.footer .fat-footer .footer-links {
    display: grid;
    gap: 25px;
    grid-template-columns: 1fr;
    width: 100%;
}
.footer .fat-footer .footer-links .ft-col {
    width: 100%;
}
.footer .fat-footer .footer-links .ft-title {
    color: #6495ed;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    margin: 0 0 10px;
    text-transform: uppercase;
}
.footer .fat-footer .footer-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.footer .fat-footer .footer-links ul li {
    margin: 0;
}
.footer .fat-footer .footer-links ul li a {
    color: #ccc;
    display: inline-block;
    font-size: 14px;
    margin: 0 0 8px;
    text-decoration: none;
}
.footer .legal-footer {
    background: #000;
    width: 100%;
}
.footer .legal-footer .footer-links {
    padding-bottom: 30px;
    padding-top: 30px;
}
.footer .legal-footer .footer-links .ft-cnt {
    display: flex;
    flex-direction: column-reverse;
}
.footer .legal-footer .rights-content {
    margin: 0 0 30px;
    text-align: center;
}
.footer .legal-footer .logo {
    margin: 0 0 30px;
    text-align: center;
}
.footer .legal-footer .logo svg {
    height: 60px;
    width: 60px;
}
.footer .legal-footer p,
.footer .legal-footer a {
    color: #727272;
}
.footer .disclaimer p {
    font-size: 12px;
    text-align: center;
}

@media only screen and (min-width: 37.5em) {
    .footer .fat-footer .footer-info {
        grid-template-columns: 1fr 1fr;
    }
    .footer .fat-footer .ft-contacts {
        justify-self: end;
    }
    .footer .fat-footer .follow-us {
        text-align: left;
    }
    .footer .fat-footer .footer-links {
        grid-template-columns: repeat(3, 1fr);
    }
    .footer .legal-footer .footer-links .ft-cnt {
        flex-direction: row;
        margin-bottom: 20px;
    }
    .footer .legal-footer .rights-content,
    .footer .legal-footer .logo {
        margin: 0;
        width: calc(100% / 3);
    }
    .footer .legal-footer .rights-content {
        align-self: flex-end;
        text-align: left;
    }
}

@media only screen and (min-width: 48em) {
    .footer .fat-footer {
        padding: 50px 0;
    }
    .footer .fat-footer .footer-info {
        gap: 100px;
        padding-bottom: 50px;
    }
}

@media only screen and (min-width: 64em) {
    .footer {
        margin-bottom: 0;
    }
    .footer .fat-footer {
        padding: 80px 0;
    }
    .footer .fat-footer .footer-info {
        margin: 0 auto 40px;
        padding-bottom: 40px;
    }
    .footer .fat-footer .footer-links {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media only screen and (min-width: 80em) {
    .footer .fat-footer .footer-info {
        gap: 180px;
    }
}